<template>
<b-card>
    <b-form @submit="onSubmit">
        <b-row>
            <b-col md="6">
                <b-form-group
                label="Name"
                >
                    <b-form-input
                        v-model="name"
                        placeholder="Name"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                    label="Role"
                >
                    <b-form-select
                        v-model="roleId"
                        name="roles.db_name"
                        :options="roles"
                        text-field="name"
                        value-field="id"
                        @change="CheckRoleId"
                    />
                </b-form-group>
            </b-col>
            <b-col
            v-if="roleId=='6'"
            md="6"
            >
                <b-form-group
                    label="Grouped Org"
                >
                    <b-form-select
                        v-model="groupOrgId"
                        name="field.company_id.db_name"
                        :options="groupOrg"
                        text-field="name"
                        value-field="id"
                    />
                </b-form-group>
            </b-col>
            <b-col
            md="6"
            >
                <b-form-group
                label="Email"
                >
                    <b-form-input
                        v-model="email"
                        placeholder="Email"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Phone Number"
                >
                    <b-form-input
                        v-model="phoneNumber"
                        placeholder="Phone Number"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col
            v-if="roleId=='4' || roleId=='8'"
            md="6"
            >
                <b-form-group
                    label="Branch"
                >
                    <b-form-select
                        v-model="branchId"
                        :options="branch"
                        name="branch.name"
                        text-field="name"
                        value-field="id"
                    />
                </b-form-group>
            </b-col>
            <b-col
            v-else
            md="6"
            >
                <b-form-group
                    label="Branch"
                >
                    <b-form-select
                        v-model="branchId"
                        :options="branch"
                        text-field="name"
                        value-field="id"
                        multiple
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Employee ID"
                >
                    <b-form-input
                        v-model="employeeId"
                        placeholder="Employee ID"
                        required="required"
                        maxlength="250"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Password"
                >
                    <b-form-input
                        v-model="password"
                        placeholder="Password"
                        maxlength="250"
                        type="password"
                    />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                label="Status"
                >
                    <b-form-checkbox
                        v-model="isActive"
                        switch
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-button
        type="submit"
        variant="primary"
        >
            Submit
        </b-button>
        <GoBack />
    </b-form>
</b-card>
</template>

<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GoBack from '../common/goBack.vue';
export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        GoBack,
        BButton,
        BFormCheckbox,
    },
    data() {
        return {
            id: 0,
            field: [],
            roles: [],
            role: '',
            name: '',
            email: '',
            phoneNumber: '',
            employeeId: '',
            branch: [],
            password: '',
            url: '',
            isActive: true,
            roleId: 0,
            // branchId: 0,
            branchId: [],
            groupOrgId: 0,
            groupOrg: [],
        };
    },
    created() {
        if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.url = `/users/view/${this.id}`;
        } else {
            this.url = `/users/createView`;
        }
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$helper.showLoading();
            this.$axios.get(this.url)
                .then(res => {
                    this.field = res.data.fields;
                    this.roles = res.data.fields.role_id.options;
                    this.groupOrg = res.data.fields.company_id.options;
                    if (this.$store.state.auth.user.roles[0].id === 4) {
                        this.branch = res.data.fields.branch_id.options.filter(item => item.id !== -1)
                        console.log(this.branch);
                    } else {
                        this.branch = res.data.fields.branch_id.options;
                    }
                    if (this.id) {
                        this.roleId = res.data.fields.role_id.value;
                        // [this.branchId] = res.data.fields.branch_id.value;
                        this.branchId = (this.roleId === 4 || this.roleId === 8) ? res.data.fields.branch_id.value[0] : res.data.fields.branch_id.value;
                        this.groupOrgId = res.data.fields.company_id.value;
                        this.name = res.data.results.result.name;
                        this.email = res.data.results.result.email;
                        this.phoneNumber = res.data.results.result.phone_number;
                        this.employeeId = res.data.results.result.employee_id;
                        this.password = res.data.fields.password.value;
                        this.isActive = (res.data.results.result.status === 1);
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
                this.$helper.hideLoading();
        },
        async onSubmit(event) {
            event.preventDefault();
            console.log(typeof this.isActive);
            let request = {};
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('branch_id', this.branchId.toString());
            formData.append('role_id', this.roleId);
            formData.append('company_id', this.groupOrgId);
            formData.append('email', this.email);
            formData.append('phone_number', this.phoneNumber);
            formData.append('employee_id', this.employeeId);
            formData.append('password', this.password);
            formData.append('status', this.isActive);
            request = formData;
            console.log(request);
            let url = '';
            if (this.id) {
                url = `/users/update/${this.id}`;
            } else {
                url = `/users/save`;
            }
            this.$axios.post(url, request)
                .then(res => {
                    console.log(res);
                    if (this.id === 0) {
                        this.$router.push(`/account/users`).catch(() => {});
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Record Updated',
                                icon: 'CheckIcon',
                                variant: 'primary',
                            },
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        CheckRoleId(val) {
            this.roleId = val;
            console.log(this.role);
        },
    },
}
</script>
